.rbc-time-content {
    display: none;
}

.rbc-label {
    display: none;
}

.rbc-time-header-content {
    border-left: none;
}

.rbc-toolbar .rbc-btn-group button:nth-child(4) {
    display: none;
}

.rbc-show-more {
    font-size: 14px;
    font-weight: 500;
    visibility: visible;
    color: transparent;
}

.rbc-show-more {
    font-size: 0;
    overflow: visible;
    padding: 4px;
}

.rbc-today {
    background-color: transparent;
}

.rbc-off-range-bg {
    background-color: transparent;
}

.rbc-show-more::after {
    content: '+2 more';
    font-size: 14px;
    font-weight: 400;
    display: block;
    line-height: normal;
    white-space: nowrap;
    color: #073B70;
}

/* customCalendar.css */

.rbc-event {
    height: auto !important;
    /* Override any fixed height */
    white-space: normal;
    /* Allow text to wrap */
}

.rbc-event-content strong {
    font-weight: 400;
}

.rbc-day-slot .rbc-event-content {
    white-space: normal !important;
    padding: 0 !important;
    background-color: transparent !important;

}

@media (max-width: 601px) {
    .rbc-show-more::after {
        content: '+2';
        color: #073B70;
    }
}

.rbc-time-view {
    padding-top: 10px;

    .text {
        background-color: red;
    }
}

.rbc-row {
    background-color: #f8f8f8;
}