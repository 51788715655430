/* ==============================
Font-family NeueHaasDisplay Start
============================== */

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Black.eot');
  src: url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Black.eot?#iefix') format('embedded-opentype'),
      url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Black.woff2') format('woff2'),
      url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Black.woff') format('woff'),
      url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Black.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Bold.eot');
  src: url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Bold.eot?#iefix') format('embedded-opentype'),
      url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Bold.woff2') format('woff2'),
      url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Bold.woff') format('woff'),
      url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Light.eot');
  src: url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Light.eot?#iefix') format('embedded-opentype'),
      url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Light.woff2') format('woff2'),
      url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Light.woff') format('woff'),
      url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Thin.eot');
  src: url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Thin.eot?#iefix') format('embedded-opentype'),
      url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Thin.woff2') format('woff2'),
      url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Thin.woff') format('woff'),
      url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Mediu.eot');
  src: url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Mediu.eot?#iefix') format('embedded-opentype'),
      url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Mediu.woff2') format('woff2'),
      url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Mediu.woff') format('woff'),
      url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Mediu.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Roman.eot');
  src: url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Roman.eot?#iefix') format('embedded-opentype'),
      url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Roman.woff2') format('woff2'),
      url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Roman.woff') format('woff'),
      url('../assets/font-family/NeueHaasDisplay/NeueHaasDisplay-Roman.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Roboto';
  src: url('../assets/font-family/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal; /* You can specify the font weight as needed (e.g., normal, bold) */
  font-style: normal; /* You can specify the font style as needed (e.g., normal, italic) */
  font-display: swap;
}


/* ==============================
Font-family NeueHaasDisplay End
============================== */
/* !============================= */
/* ==============================
Font-family Axiforma Start
============================== */

@font-face {
  font-family: 'Axiforma';
  src: url('../assets/font-family/axiforma/Axiforma-Regular.eot');
  src: url('../assets/font-family/axiforma/Axiforma-Regular.eot?#iefix') format('embedded-opentype'),
      url('../assets/font-family/axiforma/Axiforma-Regular.woff2') format('woff2'),
      url('../assets/font-family/axiforma/Axiforma-Regular.woff') format('woff'),
      url('../assets/font-family/axiforma/Axiforma-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* ==============================
Font-family Axiforma End
============================== */
/* !============================= */
/* ==============================
  Common CSS Start
============================== */

:root {
  --c-black: #000;
  --c-light-blue: #7B88A8;
  --c-dark-blue: #0C1048;
  --c-blue: #1C3D70;
  --c-white: #fff;
  --c-light-sky-color: #F8FAFC;
  --c-blue-grey: #B3BFDC;
  --c-light-grey: #ADADAD;
  --c-goldden: #FF9600;
  --c-sky: #0080FF;
  --f-NeueHassDisplay: 'Neue Haas Grotesk Display Pro';
}

html {
  scroll-behavior: smooth;
}

a:hover {
  text-decoration: none;
}


a.bgWhite, a.bgBlue:hover {
  border: 1px solid var(--c-light-blue);
  color: var(--c-light-blue);
  background-color: transparent;
  border-radius: 5px;
  transition: all .3s ease-in-out;
}

a.bgBlue, a.bgWhite:hover {
  border: 1px solid var(--c-blue);
  color: var(--c-white);
  background-color: var(--c-blue);
  border-radius: 5px;
  transition: all .3s ease-in-out;
}

button.bgWhite, button.bgBlue:hover {
  border: 1px solid var(--c-light-blue);
  color: var(--c-light-blue);
  background-color: transparent;
  border-radius: 5px;
  transition: all .3s ease-in-out;
}

button.bgBlue, button.bgWhite:hover {
  border: 1px solid var(--c-blue);
  color: var(--c-white);
  background-color: var(--c-blue);
  border-radius: 5px;
  transition: all .3s ease-in-out;
}

.login-btn {
  height: 45px;
  font-weight: 500;
}

ul {
  padding: 0px;
  margin: 0px;
}

ul li {
  list-style: none;
}

.form-control:focus {
  box-shadow: none;
}

/* ==============================
  Common CSS End
============================== */
/* !============================= */
/* ==============================
  Top-bar CSS Start
============================== */

.topBar {
  padding: 13px 0;
  border-bottom: 0.5px solid var(--c-light-blue);
}

.callUs a {
  color: var(--c-light-blue);
  font-size: 12px;
  font-weight: 400;
  font-family: 'Axiforma';
}

.callUs a i {
  margin: 0 10px 0 0;
}

.followUs, .followUs ul {
  display: flex;
  align-items: center;
  justify-content: end;
}

.followUs p {
  font-size: 14px;
  font-weight: 500;
  margin: 0 10px 0 0;
}

.followUs ul li i {
  color: var(--c-white);
  background-color: var(--c-blue);
  padding: 8px;
  font-size: 14px;
  border-radius: 50%;
  margin: 0 5px;
}

.followUs ul li i{
  margin-top: 3px;
}

/* ==============================
  Top-bar CSS End
============================== */
/* !============================= */
/* ==============================
  Navbar Css Start
============================== */

.header {
  position: absolute;
  top: 57px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 9;
}

.header.active {
  position: fixed;
  top: 0px;
  background-color: var(--c-white);
  box-shadow: 0px 0px 5px 4px #eee;
  transition: all .3s ease-in-out;
}

.logo img, .footerLogo img {
  width: auto;
}

.header .navbar {
  padding: 24px 0px;
}

.header .navbar-nav .nav-item {
  margin: 0 20px;
}

.header .navbar-nav .nav-link {
  color: var(--c-light-blue);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  padding-right: 0rem;
  padding-left: 0rem;
}

.header .navbar-nav .nav-link.active, .header .navbar-nav .nav-link:hover {
  color: var(--c-blue);
  transition: all .3s ease-in-out;
}

.header .navbar-nav .nav-link.active {
  font-weight: 700;
}

.signInBtn a {
  padding: 19px 36px;
  border: 1px solid var(--c-light-blue);
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  margin: 0 0 0 10px;
}

/* ==============================
  Navbar Css End
============================== */
/* !============================= */
/* ==============================
  Hero Section Css Start
============================== */

.heroSection {
  padding: 160px 0 120px;
  /* padding: 160px 0 360px; */
  background-image: url("../assets/img/MS-hero-bg-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.heroContent {
  max-width: 754px;
  margin: 0 auto;
  text-align: center;
}

.heroContent h1 {
  font-weight: 600;
  font-size: 50px;
  line-height: 1.3;
}

.heroContent p {
  margin: 40px 0 70px;
}

.heroContent a {
  padding: 20px 50px;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
}

.imageSec {
  max-width: 850px;
  margin: 80px auto 0px;
  position: absolute;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}

/* ==============================
  Hero Section Css End
============================== */
/* !============================= */
/* ==============================
  Features Section Css Start
============================== */

.msfeatures {
  position: relative;
  padding: 290px 0 100px;
}

.msfeatures::after, .msfeatures::before {
  content: " ";
  position: absolute;
  bottom: -180px;
  right: 0px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 80%;
  z-index: -1;
}

.msfeatures::after {
  bottom: -180px;
  right: 0px;
  background-image: url("../assets/img/MS-feature-lines-bg-img.png");
  height: 45%;
}

.msfeatures::before {
  top: 0px;
  left: 0px;
  background-image: url("../assets/img/MS-uleashing-innovation-bg-top-img.png");
  height: 55%;
}

.msfeatures.aboutPage::before {
  display: none;
}

.msfeatures .container {
  max-width: 1250px;
}

.msfeatures .container {
  background-image: url("../assets/img/MS-feature-bg-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}

.msfeatures.msfeatures.aboutPage .container {
  background-size: 120% 125%;
}

.msfeatures .row:last-child {
  justify-content: center;
}

.featuresHeading {
  max-width: 690px;
  margin: 0 auto;
  text-align: center;
}

.featuresHeading h1 {
  font-size: 40px;
  font-weight: 600;
}

.featuresHeading p {
  margin: 40px 0 90px;
}

.aboutPage .featuresHeading p {
  margin: 30px 0 90px;
}

.featureContent {
  position: relative;
}

.featureImg {
  height: 100px;
  width: 100px;
  background-color: var(--c-blue);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
}

.featureImg img {
  height: 50px;
  width: 50px;
}

.featureText {
  border: 0.5px solid var(--c-light-blue);
  background-color: var(--c-light-sky-color);
  border-radius: 15px;
  padding: 75px 16px 25px;
  text-align: center;
  margin: 0 0 100px;
  min-height: 409px;
}

.featureText h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 25px;
}

.featureText p {
  line-height: 1.3;
}

.teamContent {
  position: relative;
}

.teamImg {
  height: 100px;
  width: 100px;
  background-color: var(--c-blue);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  clip-path: circle(50%);

}

.teamImg img {
  width: 90%;
  object-fit: cover;
}

.teamText {
  border: 0.5px solid var(--c-light-blue);
  background-color: var(--c-light-sky-color);
  border-radius: 15px;
  padding: 75px 16px 25px;
  text-align: center;
  margin: 0 0 100px;
  min-height: 350px;
}

.teamText .contact-icons {
  margin-top: 30px;
  left: calc(50% - 35px);
  display: flex;
  justify-content: center;
}

.contact-icons .contactImg {
  width: 30px;
  height: 30px;
  background-color: #B3BFDC;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.contact-icons .contact-icons-box {
  width: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contact-icons img {
  height: 14px;
}

.teamText h4 {
  font-size: 20px;
  font-weight: 600;
}

.teamText h2 {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 30px;
}

.teamText p {
  font-size: 14px;
}

/* ==============================
  Features Section Css End
============================== */
/* !============================= */
/* ==============================
  Video Section Css Start
============================== */

.videoSec {
  padding: 100px 0;
}

.videoSec .videoHeading h2 {
  font-size: 40px;
  font-weight: 600;
  margin: 0 0 40px;
}

.videoSec .video {
  max-width: 732px;
  margin: 0px auto;
}

.video button.btn:focus {
  box-shadow: none;
}

#videoPopup .modal-dialog {
  height: 90%;
  max-width: 700px;
}

#videoPopup .modal-content {
  height: 100%;
  background-color: var(--c-light-sky-color);
}

#videoPopup .modal-header {
  border-bottom: 0px;
  padding: 1rem 1rem 0rem 1rem;
}

#videoPopup .modal-header button span {
  color: var(--c-blue);
  font-size: 25px;
}

.videoLink {
  height: 100%;
}

.videoLink iframe {
  width: 100%;
  height: 100%;
}

/* ==============================
  Video Section Css End
============================== */
/* !============================= */
/* ==============================
  Experience Feature Section Css Start
============================== */

.experienceFeature {
  padding: 110px 0 170px;
  background-image: url("../assets/img/MS-experience-the-feature-bg-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.experienceFeature .row {
  align-items: center;
}

.experienceContent h2 {
  font-size: 40px;
  font-weight: 600;
}

.experienceContent p {
  margin: 40px 0 70px;
}

.experienceContent a {
  padding: 20px 50px;
  font-weight: 600;
}

/* ==============================
  Experience Feature Section Css End
============================== */
/* !============================= */
/* ==============================
  Testimonial Section Css Start
============================== */

.testimonial {
  padding: 80px 0;
}

.testimonialHeading h1 {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  margin: 0 0 60px;
  text-transform: capitalize;
}

.sliderContent {
  border-radius: 15px;
  border: 0.5px solid var(--c-light-blue);
  background-color: var(--c-light-sky-color);
  padding: 25px 22px;
  text-align: center;
}

.owl-carousel .owl-item .sliderImg img {
  height: 55px;
  width: 55px;
  margin: 0 auto;
}

.sliderHeading h4 {
  font-size: 25px;
  font-weight: 600;
  margin: 20px 0;
}

.ratings i {
  color: var(--c-goldden);
  font-size: 18px;
  margin: 0 0 20px;
}

.sliderDescription p {
  font-size: 16px;
}

.owl-theme .owl-dots .owl-dot span {
  background: var(--c-blue-grey);
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: var(--c-blue);
}

.testimonial .owl-theme .owl-dots {
  margin: 40px 0 0;
}

.testimonial .owl-carousel .owl-nav button.owl-prev, .testimonial .owl-carousel .owl-nav button.owl-next {
  position: absolute;
  top: 35%;
  color: transparent;
}

.testimonial .owl-carousel .owl-nav button.owl-prev {
  left: -50px;
}

.testimonial .owl-carousel .owl-nav button.owl-next {
  right: -50px;
}

.testimonial .owl-carousel .owl-nav button.owl-prev::after, .testimonial .owl-carousel .owl-nav button.owl-next::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 50px;
  width: 50px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.testimonial .owl-carousel .owl-nav button.owl-prev::after {
  background-image: url("../assets/img/MS-Prev-button-img.png");
}

.testimonial .owl-carousel .owl-nav button.owl-next::after {
  background-image: url("../assets/img/MS-Next-button-img.png");
}

/* ==============================
  Testimonial Section Css End
============================== */
/* !============================= */
/* ==============================
  Footer Section Css Start
============================== */

footer {
  padding: 70px 0 0;
  background-image: url("../assets/img/MS-footer-bg-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  z-index: 1;
}

footer::after {
  content: " ";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url("../assets/img/MS-footer-vector-line-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: -1;
}

.footerLogoContent {
  padding: 0 44px 0 0;
}

.footerLogoContent p {
  font-size: 12px;
  margin: 20px 0;
}

.footerHeading {
  margin: 0 0 0 0px;
}

.footerNavigation {
  margin: 0 0 0 40px;
}

.footerSocialicon {
  margin: 0 0 0 75px;
}

.footerHeading h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 30px;
}

ul.footerLinks {
  padding: 0px;
  margin: 0px;
}

ul.footerLinks li a {
  color: var(--c-light-blue);
  font-size: 15px;
  font-weight: 500;
}

.footerSocialLinks {
  display: flex;
  flex-wrap: wrap;
}

.footerSocialLinks li a i {
  color: var(--c-blue);
  background-color: var(--c-blue-grey);
  border-radius: 50%;
  padding: 10px;
  margin: 0 15px 10px;
}

.footerSocialLinks li a i:first-child, .footerSocialLinks li a i:nth-child(4) {
  margin: 0 15px 10px 0;
}

.newsLetter p {
  font-size: 15px;
  font-weight: 500;
  margin: 0 0 30px;
}

.newsLetter form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: var(--c-white);
}

.newsLetter input {
  font-size: 12px;
  font-weight: 500;
  border: 0px;
  outline: none;
  padding: 16px 25px;
}

.newsLetter input.form-control:focus {
  box-shadow: none;
}

.newsLetter form .form-group:first-child {
  flex: 0 0 200px;
}

.newsLetter input[type="email"] {
  border-radius: 10px 0 0 10px;
  height: auto;
  padding: 16px 0px 16px 25px;
  max-width: 200px;
}

.newsLetter input[type="email"]::placeholder {
  color: var(--c-light-grey);
}

.newsLetter input[type="submit"] {
  color: var(--c-white);
  background-color: var(--c-blue);
  border-radius: 10px;
}

.copyRight {
  padding: 23px 0;
  margin: 90px 0 0;
  border-top: 0.5px solid var(--c-light-blue);
  text-align: center;
}

.copyRight p {
  color: #596686;
  font-size: 13px;
  font-weight: 500;
}

/* ==============================
  Footer Section Css End
============================== */
/* !============================= */
/* ==============================
  About MS-Logger Breadcrums Section Css Start
============================== */

.header.bgColorWhite {
  background-color: var(--c-white);
}

.breadCrums {
  padding: 185px 0 90px;
  background-color: var(--c-light-sky-color);
}

.crumsHeading {
  text-align: center;
}

.crumsHeading h1 {
  font-size: 40px;
  font-weight: 600;
  margin: 0 0 10px;
}

.crumsHeading ul li a.breadCrumsLink {
  color: var(--c-light-blue);
  font-size: 12px;
  font-weight: 500;
}

.crumsHeading ul li a.breadCrumsLink.home {
  color: var(--c-blue);
  margin: 0 5px 0 0;
}

.msfeatures.aboutPage {
  padding: 80px 0 0;
}

.msfeatures.aboutPage::after {
  display: none;
}

/* ==============================
  About MS-Logger Breadcrums Section Css End
============================== */
/* !============================= */
/* ==============================
  About Mbl App Section Css Start
============================== */

.mblSite {
  padding: 0 0 100px;
}

.mblSite .row {
  background-image: url("../assets/img/MS-mbl-bg-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 20px;
  padding: 80px 0 80px 10px;
  position: relative;
  align-items: center;
}

.mblSite.homePage .row {
  padding: 80px 85px;
}

.mblSite .row::after {
  content: " ";
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-image: url("../assets/img/MS-mbl-vector-line-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: -1;
}

.mblSiteContent h2 {
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 40px;
}

.mblSiteContent h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 30px 0 20px;
}

/* ==============================
  About Mbl App Section Css End
============================== */
/* !============================= */
/* ==============================
  Choose Plan Section Css Start
============================== */

.choosePlan {
  padding: 100px 0 0;
}

.planHeading {
  max-width: 708px;
  margin: 0 auto;
  text-align: center;
}

.planHeading h1 {
  font-size: 40px;
  font-weight: 600;
  text-transform: capitalize;
}

.planHeading p {
  margin: 30px 0 40px;
}

/* Plan & Pricing CSS */

.tab-slider--nav {
  width: 100%;
}

.tab-slider--tabs {
  display: block;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  position: relative;
  border-radius: 100px;
  background-color: #D5DDF2;
  width: 85px;
  height: 40px;
  -webkit-user-select: none;
    -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.tab-slider--tabs:after {
  content: "";
  width: 32px;
  background-color: var(--c-blue);
  height: 32px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
  transition: all 250ms ease-in-out;
  border-radius: 32px;
}
.tab-slider--tabs.slide:after {
  left: 57%;
}

.tab-slider--trigger {
  font-size: 12px;
  line-height: 1;
  font-weight: bold;
  color: #345F90;
  text-transform: uppercase;
  text-align: center;
  padding: 11px 20px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  display: inline-block;
  transition: color 250ms ease-in-out;
  -webkit-user-select: none;
    -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.tab-slider--trigger.active {
  color: #fff;
}

.tab-slider--tabs .tab-slider--trigger:first-child:after {
  content: "Monthly";
  left: -85px;
}

.tab-slider--tabs .tab-slider--trigger:last-child:after {
  content: "Yearly";
  right: -65px;
}

.tab-slider--tabs .tab-slider--trigger::after {
  position: absolute;
  top: 50%;
  color: var(--c-blue);
  font-size: 20px;
  font-weight: 600;
  font-family: var(--f-NeueHassDisplay);
  text-transform: capitalize;
  transform: translateY(-10%);
}

.tab-slider--container {
  background-image: url("../assets/img/MS-plan-bg-img.png");
  background-repeat: no-repeat;
  background-size: 100% 140%;
  background-position: center center;
  padding: 90px 0;
}

.tab-slider--container .row {
  align-items: center;
}

.planContent {
  border-radius: 15px;
  border: 0.5px solid var(--c-light-blue);
  background-color: var(--c-white);
  padding: 25px 17px;
}

.tab-slider--body .col-xl-3, .tab-slider--body .col-md-6 {
  padding-left: 10px;
  padding-right: 10px;
}

.planContent.activePlan {
  background-color: var(--c-blue);
}

.planContent.activePlan p, .planContent.activePlan h1, .planContent.activePlan ul li {
  color: var(--c-white);
}

.planContent.activePlan .signUpPlan a {
  background-color: var(--c-white);
  color: var(--c-blue);
  border: 0.5px solid var(--c-white);
}

.planContent.activePlan .planTopImg {
  text-align: center;
  margin: 0 0 25px;
}

.planContent.activePlan .planTopImg img {
  width: auto;
}

.planContent p {
  text-align: center;
  font-weight: 600;
}

.planContent h1 {
  font-size: 35px;
  font-weight: 700;
  margin: 25px 0;
  text-align: center;
}

.planContent ul li {
  color: var(--c-blue);
  font-size: 12px;
  font-weight: 500;
  padding: 5px 0 5px 35px;
  position: relative;
}

.planContent ul li::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  background-image: url("../assets/img/MS-plan-list-icon.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.planContent .signUpPlan {
  margin: 25px 0 0;
}

.planContent a.form-control {
  text-align: center;
  border-radius: 10px;
  height: auto;
  padding: 17px;
  font-weight: 600;
}

/* ==============================
  Choose Plan Section Css End
============================== */
/* !============================= */
/* ==============================
  Contact Us Section Css Start
============================== */

.contactUs {
  padding: 100px 0;
}

.contactHeading {
  max-width: 690px;
  margin: 0 auto;
}

.contactHeading h1 {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  margin: 0 0 60px;
  text-transform: capitalize;
}

.contactForm {
  max-width: 996px;
  margin: 0 auto;
  border-radius: 15px;
  border: 0.5px solid var(--c-light-blue);
  background: var(--c-light-sky-color);
  padding: 65px 50px;
  position: relative;
}

.contactForm::after {
  content: " ";
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 165%;
  background-image: url("../assets/img/MS-contact-form-bg-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: -1;
}

.contactForm .form-group {
  margin: 0 0 30px;
}

.contactForm form label {
  color: var(--c-dark-blue);
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 10px;
}

.contactForm form label sup {
  color: #EC4F3C;
  font-size: 16px;
  font-weight: 600;
  font-family: var(--f-NeueHassDisplay);
  top: 0em;
}

.contactForm form input.form-control, .contactForm form textarea.form-control, .contactForm form select.form-control {
  border-radius: 10px;
  border: 0.5px solid #7B88A8;
  color: var(--c-dark-blue);
  background-color: var(--c-white);
  padding: 15px 24px;
  font-size: 16px;
  font-weight: 500;
  height: auto;
}

.contactForm form input.form-control::placeholder, .contactForm form textarea.form-control::placeholder {
  color: #C5C4C4;
}

/* checkedbox css */

.contactForm .formSubmission .form-group input[type="checkbox"], .logIn .remember input[type="checkbox"] {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.contactForm .formSubmission .form-group label, .logIn .remember label {
  position: relative;
  cursor: pointer;
}

.remember {
  font-size: 14px;
}

.forget {
  margin-top: -4px;
}

.forget-row {
  display: flex;
  justify-content: space-between;
}

.contactForm .formSubmission .form-group label:before, .logIn .remember label::before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid var(--c-black);
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  height: 20px;
  width: 20px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
}

.logIn .remember label::before {
  height: 16px;
  width: 16px;
  margin: 0 10px 3px 0;
}

.contactForm .formSubmission .form-group input:checked + label:before, .logIn .remember input:checked + label::before {
  background-color: var(--c-blue);
  border: 1px solid var(--c-blue);
}

.contactForm .formSubmission .form-group input:checked + label:after, .logIn .remember input:checked + label::after {
  content: '';
  display: block;
  position: absolute;
  top: 46%;
  left: 7px;
  width: 6px;
  height: 14px;
  border: solid var(--c-white);
  border-width: 0 2px 2px 0;
  transform: translateY(-50%) rotate(45deg);
  background-color: var(--c-blue);
}

.pointer {
  cursor: pointer;
}

.logIn .remember input:checked + label::after {
  left: 5px;
  top: 33%;
}

.contactForm .formSubmission {
  margin: 30px 0 0;
}

.contactForm .formSubmission label {
  color: var(--c-black);
  font-weight: 500;
  margin: 0px 10px;
}

.contactForm .formSubmission label a {
  color: var(--c-blue);
  font-weight: 700;
}

.contactForm .formSubmission input[type="submit"] {
  border-radius: 10px;
  color: var(--c-white);
  background-color: var(--c-blue);
  padding: 15px 60px;
  border: 0.5px solid var(--c-blue);
  outline: none;
  font-size: 16px;
  font-weight: 700;
  transition: all .3s ease-in-out;
}

.contactForm .formSubmission input[type="submit"]:hover {
  color: var(--c-light-blue);
  background-color: var(--c-light-sky-color);
  border: 0.5px solid var(--c-light-blue);
  transition: all .3s ease-in-out;
}

.contactCards {
  max-width: 996px;
  margin: 50px auto 0px;
}

.coardInfo {
  text-align: center;
  padding: 35px 50px;
  border-radius: 15px;
  border: 0.5px solid var(--c-light-blue);
  background: var(--c-light-sky-color);
  min-height: 194px;
}

.cardIcon img {
  height: 46px;
  width: 46px;
  margin: 0 auto 20px;
}

.cardText p {
  font-size: 13px;
  line-height: 1.3;
  margin: 0 0 5px;
}

.cardText p a {
  color: var(--c-light-blue);
}

/* ==============================
  Contact Us Section Css End
============================== */
/* !============================= */
/* ==============================
  SignUp Page Section Css Start
============================== */

.signUp {
  padding: 190px 0 75px;
  background-image: url("../assets/img/MS-signup-bg-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  z-index: 0;
}

.step, .formValidate {
  display: none;
}

.step.active {
  display: block;
}

.errorMessage p {
  color: red;
  text-align: center;
  font-size: 16px;
  margin: 0 0 20px;
}

.formValidate p {
  color: red;
  text-align: center;
  font-size: 16px;
  margin: 0 0 20px;
}

.signUp .contactForm {
  max-width: 500px;
}

.signUp .contactForm h1 {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 35px;
}

.signUp .owl-item {
  width: 388px;
}

.signUp .owl-carousel .owl-item .item {
  display: none;
  opacity: 0;
  transition: all .3s ease-in-out;
}

.signUp .owl-carousel .owl-item.active .item {
  display: block;
  opacity: 1;
  transition: all .3s ease-in-out;
}

/* .signUp .item.bgCardImg::before {
  content: " ";
  position: absolute;
  top: 60%;
  left: -300px;
  transform: translateY(-50%);
  width: 448px;
  height: 352px;
  background-image: url("../assets/img/MS-signup-bg-card-img.png");
  background-repeat: no-repeat;
  background-size: 448px 352px;
  z-index: -1;
} */

.signUp .contactForm .form-group {
  margin: 0 0 20px;
}

.signUp .contactForm form label {
  font-weight: 500;
}

.signUp .contactForm .strong {
  position: relative;
  margin: 35px 0 0;
}

.signUp .contactForm .strongPassword {
  position: absolute;
  top: -25px;
  right: 0px;
}

.signUp .contactForm .strongPassword p {
  color: var(--c-sky);
  font-size: 12px;
  font-weight: 600;
}

.signUpTerms {
  height: 320px;
  overflow-y: scroll;
}

.signUpTerms h4 {
  color: var(--c-blue);
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 20px;
}

.signUpTerms h4 i {
  margin: 0 15px 0 0;
}

.signUpTerms p {
  color: var(--c-black);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
}

/* input awitch css */

.toggle {
  position: relative;
  top: 2px;
  overflow: hidden;
}
.toggle input[type="checkbox"] {
  position: relative;
  appearance: none;
  width: 52px;
  height: 24px;
  background-color: #D5DDF2;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.4s;
}
.toggle input:checked[type="checkbox"] {
  background-color: #D5DDF2;
}
.toggle input[type="checkbox"]::after {
  position: absolute;
  content: "";
  left: 4px;
  top: 50%;
  width: 20px;
  height: 20px;
  background-color: var(--c-blue);
  border-radius: 50%;
  transform: translateY(-50%);
  transition: all 0.4s;
}
.toggle input[type="checkbox"]:checked:after {
  background-color: green;
}
.toggle input:checked[type="checkbox"]::after {
  left: 28px;
}
.toggle input:checked[type="checkbox"] ~ label.on {
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}
.toggle input:not(:checked[type="checkbox"]) ~ label.off {
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.toggle1 {
  position: relative;
  top: 2px;
  overflow: hidden;
}
.toggle1 input[type="checkbox"] {
  position: relative;
  appearance: none;
  width: 64px;
  height: 35px;
  background-color: #D5DDF2;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.4s;
}
.toggle1 input:checked[type="checkbox"] {
  background-color: #D5DDF2;
}
.toggle1 input[type="checkbox"]::after {
  position: absolute;
  content: "";
  left: 4px;
  top: 50%;
  width: 30px;
  height: 30px;
  background-color: var(--c-blue);
  border-radius: 50%;
  transform: translateY(-50%);
  transition: all 0.4s;
}

.toggle1 input:checked[type="checkbox"]::after {
  left: 28px;
}
.toggle1 input:checked[type="checkbox"] ~ label.on {
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}
.toggle1 input:not(:checked[type="checkbox"]) ~ label.off {
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.plan-toggle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.acceptTerms {
  border: 0.5px solid var(--c-light-blue);
  border-radius: 10px;
  background-color: var(--c-white);
  padding: 15px 20px;
  margin: 50px 0 0;
}

.acceptTerms .acceptContent p, .billingInfo p  {
  font-size: 14px;
  font-weight: 500;
}

.acceptTerms .acceptContent p a {
  color: var(--c-blue);
  font-weight: 600;
}

.navigationBtn {
  margin: 50px 0 0;
}

.navigationBtn button {
  border: 0;
  background-color: transparent;
  color: var(--c-blue);
  font-size: 18px;
  font-weight: 700;
  position: relative;
}

.navigationBtn button:disabled {
  color: var(--c-light-blue);
}

.navigationBtn button::after {
  content: " ";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 10.202px;
  height: 10.202px;
}

.navigationBtn button.nextBtn::after {
  right: 4px;
  background-image: url("../assets/img/MS-sign-up-next-icon.png");
}

.navigationBtn button.prevbtn::after {
  left: 4px;
  background-image: url("../assets/img/MS-sign-up-prev-icon.png");
}

.navigationBtn button.nextBtn {
  padding: 0 20px 0 0;
}

.navigationBtn button.prevbtn {
  padding: 0 0 0 20px;
}

.navigationBtn button[type="submit"] {
  color: var(--c-white);
  background-color: var(--c-blue);
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 30px;
}

.navigationBtn ul li.dots {
  border-radius: 50%;
  width: 7.545px;
  height: 7.545px;
  margin: 0 5px;
  background-color: var(--c-blue-grey);
}

.navigationBtn ul li.dots.active {
  background-color: var(--c-blue);
}

.billingInfo p {
  text-align: center;
  font-size: 14px;
}

.billingInfo p b {
  color: var(--c-blue);
}

.moderateVal {
  background-color: var(--c-blue);
  border-radius: 10px;
  padding: 15px;
  color: var(--c-white);
  text-align: center;
  margin: 25px 0 60px;
}

.moderateVal h5 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 5px;
}

.moderateVal p {
  color: var(--c-white);
  font-size: 15px;
  font-weight: 500;
}

.payment h4 {
  font-size: 16px;
  font-weight: 600;
}

.payment p {
  font-size: 14px;
  font-weight: 500;
  margin: 10px 0 20px;
}

/* Radio buttons css */

.contactForm  .paymentMethods label {
  display: flex;
  justify-content: end;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin: 0px;
}
.paymentMethods label input {
  position: absolute;
  left: -9999px;
}
.paymentMethods label input:checked + span:before {
  box-shadow: inset 0 0 0 0.4375em var(--c-blue);
}
.paymentMethods label span {
  display: flex;
  align-items: center;
  padding: 0.375em 0.75em 0.375em 0.375em;
  border-radius: 99em;
  transition: 0.25s ease;
}
.paymentMethods label span:before {
  display: flex;
  flex-shrink: 0;
  content: "";
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin-right: 0.375em;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.115em var(--c-blue);
}

.signUp .contactForm .paymentMethods .form-group {
  padding: 20px 25px;
  border: 1px solid var(--c-light-blue);
  border-radius: 10px;
  margin: 0 0 10px;
}

.paymentCard h4 {
  font-size: 20px;
  font-weight: 600;
}

.paymentCard h4 i, .paymentCard img {
  margin: 0 15px 0 0;
}

.varifyImg img {
  width: auto;
  margin: 0 auto 30px;
}

.verification p {
  font-size: 16px;
  margin: 0 0 10px;
}

.resendMail a {
  color: var(--c-blue);
  font-size: 12px;
  font-weight: 600;
  border-bottom: 1px solid var(--c-blue);
}

/* ==============================
  SignUp Page Section Css End
============================== */
/* !============================= */
/* ==============================
  Login Page Css Start
============================== */

.logIn {
  padding: 155px 0 100px;
  background-image: url("../assets/img/MS-loging-bg-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
}

.logIn::before {
  content: " ";
  position: absolute;
  top: 1px;
  left: 1px;
  height: 100%;
  width: 50%;
  background-color: var(--c-blue);
}

.logIn .row {
  align-items: center;
}

.logIn .row .col-md-6:first-child {
  padding-left: 0px;
}

.loginImages {
  padding: 0 80px 0 0;
}

.loginLogoimg {
  text-align: center;
  margin: 0 0 50px;
}

.loginLogoimg img {
  width: auto;
}

.logIn .contactForm {
  max-width: 488px;
  padding: 40px;
  margin: 30px 40px 0px 80px;
}

.logIn .contactForm h1 {
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 35px;
}

.logIn .contactForm::after, .signUp .contactForm::after {
  display: none;
}

.logIn .contactForm .form-group {
  margin: 0 0 20px;
}

.logIn .contactForm form .remember label {
  margin: 0px;
  color: var(--c-light-blue);
  font-size: 12px;
  font-weight: 600;
}

.forget a {
  color: var(--c-blue);
  font-size: 12px;
  font-weight: 600;
}

.logIn .or p {
  color: var(--c-blue-grey);
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  position: relative;
}

.logIn .or p::before, .logIn .or p::after {
  content: " ";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 1px;
  width: 130px;
  background-color: var(--c-blue-grey);
}

.logIn .or p::before {
  left: 0px;
}

.logIn .or p::after {
  right: 0px;
}

.signInGoogle a.form-control {
  color: var(--c-blue-grey);
  background-color: var(--c-white);
  border: 0.5px solid #7B88A8;
  border-radius: 10px;
  padding: 15px 24px;
  font-size: 16px;
  font-weight: 500;
  height: auto;
  text-align: center;
  position: relative;
}

.signInGoogle a.form-control::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-400%, -50%);
  height: 25px;
  width: 25px;
  background-image: url("../assets/img/MS-login-with-google-icon.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

/* ==============================
  Login Page Css End
============================== */
/* !============================= */
/* ==============================
  Media Query Css Start
============================== */

@media screen and (max-width: 1200px) {

  .signInBtn a {
    padding: 15px 30px;
  }
  .heroContent h1 {
    font-size: 45px;
  }
  .experienceContent h2 {
    font-size: 32px;
  }
  .heroContent a, .experienceContent a {
    padding: 16px 40px;
  }
  .testimonialHeading h1 {
    font-size: 35px;
  }
  .testimonial .owl-carousel .owl-nav button.owl-prev, .testimonial .owl-carousel .owl-nav button.owl-next {
    display: none;
  }
  .footerLogoContent {
    padding: 0;
  }
  .footerNavigation{
    margin: 0 0 0 15px;
  }
  .footerSocialicon {
    margin: 0 0 0 50px;
  }
  .newsLetter form .form-group:first-child {
    flex: 0 0 180px;
  }
  .newsLetter input[type="email"] {
    max-width: 180px;
  }
  .newsLetter input {
    padding: 12px 20px;
  }
  .logIn .contactForm {
    margin: 30px 0px 0px 50px;
  }
  .mblSite.homePage .row {
    padding: 80px 45px;
  }
  /* .signUp .item.bgCardImg::before{
    left: -210px;
    width: 280px;
  } */
}

@media screen and (max-width: 991px) {
  .sidepanel  {
    width: 0;
    position: fixed;
    z-index: 99;
    height: 100vh;
    top: 0px;
    right: 0;
    background-color: var(--c-white);
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }
  
  .sidepanel a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    color: var(--c-light-blue);
    display: block;
    transition: 0.3s;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .sidepanel a:hover {
    color: var(--c-blue);
  }

  .sidepanel .sigUp a, .sidepanel .sigIn a {
    padding: 15px 30px;
    margin: 0 15px;
    text-align: center;
  }

  .sidepanel .sigUp {
    margin: 10px 0;
  }

  .sidepanel a.bgBlue, .sidepanel a.bgWhite:hover {
    color: var(--c-white);
  }

  .sidepanel a.bgBlue:hover {
    color: var(--c-light-blue);
  }
  
  .sidepanel .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
  }
  
  .openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: var(--c-blue);
    border: 1px solid var(--c-blue);
    border-radius: 5px;
    color: white;
    padding: 10px 20px;
    border: none;
    position: absolute;
    top: 30px;
    right: 25px;
    z-index: 9;
  }
  
  .openbtn:hover {
    background-color: var(--c-white);
    border: 1px solid var(--c-blue);
    color: var(--c-blue);
    transition: all .3s ease-in-out;
  }
  .heroSection {
    padding: 160px 0 100px;
    /* padding: 160px 0 328px; */
  }
  .videoSec {
    padding: 80px 0;
  }
  .footerNavigation, .footerSocialicon {
    margin: 0 0 0 0px;
  }
  .experienceFeature {
    padding: 90px 0 120px;
  }
  .tab-slider--container {
    padding: 70px 0;
  }
  .msfeatures {
    padding: 250px 0 90px;
  }
  .contactForm .formSubmission .form-group:first-child {
    display: flex;
    align-items: start;
  }
  .contactForm .formSubmission .form-group:first-child input {
    margin: 5px 10px 0 0;
  }
  .coardInfo {
    padding: 35px 15px;
  }
  .logIn .contactForm {
    padding: 30px;
    margin: 30px 0px 0px 20px;
  }
  .footerLogoContent {
    padding: 0 115px 0 0;
  }
  .mblSite .row {
    /* align-items: end; */
    padding: 50px 0;
  }
  .loginImages {
    padding: 0 20px 0 0;
  }
  .logIn .or p::before, .logIn .or p::after {
    width: 100px;
  }
  /* .signUp .item.bgCardImg::before {
    display: none;
  } */
  .mblSiteContent h2 {
    font-size: 25px;
    margin: 0 0 30px;
  }
  .mblAppImg {
    margin: 0 0 40px;
  }
  .contactForm .formSubmission .form-group input:checked + label:after {
    top: 26%;
  }
  .logIn .row .col-md-6:first-child {
    padding-left: 15px;
  }
  .choosePlan {
    padding: 80px 0 0;
  }
  .mblSite.homePage .row {
    padding: 50px 0px;
  }
}

@media screen and (max-width: 767px) {
  .callUs {
    text-align: center;
  }
  .followUs {
    justify-content: center;
    margin: 10px 0 0;
  }
  .header {
    top: 0px;
  }
  .heroContent h1 {
    font-size: 40px;
  }
  /* .heroSection {
    padding: 160px 0 270px;
  } */
  .msfeatures {
    padding: 200px 0 80px;
  }
  .featuresHeading h1 {
    font-size: 30px;
  }
  .featureText {
    min-height: 300px;
  }
  .experienceContent {
    margin: 0 0 60px;
    text-align: center;
  }
  .experienceFeature.aboutMS .experienceContent {
    margin: 60px 0 0;
  }
  .mblSiteContent {
    margin: 0px 0 60px;
    text-align: center;
  }
  .mblSite .row, .mblSite .row::after {
    background-size: cover;
  }
  .mblSite .row {
    margin: 0 10px;
  }
  .choosePlan {
    padding: 70px 0 0;
  }
  .footerHeading h4 {
    margin: 30px 0 20px;
  }
  .heroSection, .msfeatures, .msfeatures::after, .msfeatures::before, .msfeatures .container {
    background-size: cover;
  }
  .experienceFeature {
    background-size: 200% 100%;
  }
  .tab-slider--container {
    background-size: 135% 125%;
  }
  footer, footer::after {
    background-size: cover;
  }
  .msfeatures::before, .msfeatures::after {
    height: 20%;
  }
  .crumsHeading h1 {
    font-size: 35px;
  }
  .mblSite .row {
    flex-direction: column-reverse;
  }
  .contactHeading h1 {
    font-size: 32px;
  }
  .contactForm {
    padding: 40px 25px;
  }
  .coardInfo {
    padding: 35px 50px;
  }
  .logIn::before {
    height: 43%;
    width: 100%;
    top: unset;
    bottom: 0px;
  }
  .logIn .row .col-md-6:first-child {
    margin: 0 0 0px;
  }
  .logIn .contactForm {
    margin: 0px 0px 140px 0px;
  }
  .logIn {
    background-size: 300% 100%;
    padding: 155px 0 0px;
  }
  .logIn .row {
    flex-direction: column-reverse;
  }
  .loginLogoimg {
    margin: 0 0 30px;
  }
  .footerLogoContent {
    padding: 0 0px 0 0;
  }
  .loginImages {
    padding: 0 0px 0 0;
    margin: 0 0 80px;
  }
  .topBar {
    display: none;
  }
  .planHeading h1 {
    font-size: 35px;
  }
}

@media screen and (max-width: 480px) {
  .openbtn {
    font-size: 18px;
    padding: 8px 15px;
    right: 5px;
  }
  /* .heroSection {
    padding: 160px 0 225px;
  } */
  .heroContent h1 {
    font-size: 28px;
  }
  .heroContent p {
    margin: 30px 0 55px;
  }
  .imageSec {
    margin: 60px auto 0px;
  }
  .msfeatures {
    padding: 130px 0 70px;
  }
  .featuresHeading h1 {
    font-size: 25px;
  }
  .experienceContent h2 {
    font-size: 28px;
  }
  .experienceContent p {
    margin: 35px 0 55px;
  }
  .heroContent a, .experienceContent a {
    padding: 12px 30px;
  }
  .testimonialHeading h1 {
    font-size: 30px;
  }
  .followUs ul li i {
    padding: 5px;
  }
  .signUp .contactForm .owl-theme .owl-dots .owl-dot span {
    width: 8px;
    height: 8px;
    margin: 5px 5px;
  }
  .signUp .owl-carousel .owl-nav button.owl-prev::after {
    right: 60px;
  }
  .signUp .owl-carousel .owl-nav button.owl-next::after {
    left: 90px;
  }
  .newsLetter form .form-group:first-child {
    flex: 0 0 150px;
  }
  .newsLetter input[type="email"] {
    max-width: 150px;
  }
  .navigationBtn ul li.dots {
    width: 5.545px;
    height: 5.545px;
    margin: 0 3px;
  }
}

@media screen and (max-width: 380px) {
  .signUp .owl-carousel .owl-nav button.owl-prev::after {
    right: 35px;
  }
  .signUp .owl-carousel .owl-nav button.owl-next::after {
    left: 60px;
  }
  /* .heroSection {
    padding: 160px 0 80px;
  } */
  .contactForm .formSubmission .form-group input:checked + label:after {
    top: 16%;
  }
  .logIn::before {
    height: 40%;
  }
}

/* ==============================
  Media Query Css End
============================== */
/* !============================= */

.test {
  background-color: red !important;
  width: 100% !important;
}